import React, {useEffect, useState} from 'react'
import "../stylesheets/Outreach.css";
import '@sendbird/uikit-react/dist/index.css';
import {View, ScrollView, StyleSheet, Text, TouchableOpacity, Image, TextInput} from 'react-native-web';
import SendbirdChat from "@sendbird/chat";
import {
    GroupChannelFilter, GroupChannelListOrder,
    GroupChannelModule, MessageCollectionInitPolicy, MessageFilter,
    PublicChannelFilter,
    SuperChannelFilter
} from "@sendbird/chat/groupChannel";
import {getActiveBusinessCreators, getBusinessSummary} from "../services/BusinessService";
import plusIcon from "../icons/grey-plus-btn.png";
import Modal from "react-modal";
import {PushNotificationDeliveryOption} from "@sendbird/chat/message";
import sendIcon from "../icons/blue-send-icon.png";
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import Header from "./Header";

const Message = () => {

    const [messagingConnection, setMessagingConnection] = useState({})
    const [businessSummary, setBusinessSummary] = useState({})
    const [messageCollection, setMessageCollection] = useState({})
    const [activeMessages, setActiveMessages] = useState([])
    const [selectedUser, setSelectedUser] = useState({})
    const [messageInput, setMessageInput] = useState("")
    const [activeCreators, setActiveCreators] = useState([])
    const [channels, setChannels] = useState([])
    const [newMessageModal, setNewMessageModal] = useState(false)

    const sb = SendbirdChat.init({
        appId: '960C8598-DEDE-43E0-89FA-45442999623C',
        modules: [
            new GroupChannelModule(),
        ],
    });

    function afterOpenModal() {

    }

    function closeModal() {
        setNewMessageModal(false);
    }

    function openChatModal() {
        const token = localStorage.getItem("token");
        getActiveBusinessCreators(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setActiveCreators(resp.data);
                    setNewMessageModal(true);
                }
            })
    }

    function createNewMessage(user) {
        const params = {
            invitedUserIds: [user.id],
            name: 'Test Channel',
            operatorUserIds: [businessSummary.businessId],
            isDistinct: true,
        };
        sb.groupChannel.createChannel(params)
            .then(resp => {
                loadUsers(businessSummary.businessId);
                setNewMessageModal(false);
            })
    }

    function openChat(user) {
        // const filter = new MessageFilter();
        // const limit = 50;
        // const startingPoint = Date.now();
        // const collection = user.channel.createMessageCollection({
        //     filter,
        //     limit,
        //     startingPoint,
        // });
        // collection
        //     .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        //     .onCacheResult((err, messages) => {
        //         // Messages are retrieved from the local cache.
        //         // They might be too outdated or far from the startingPoint.
        //     })
        //     .onApiResult((err, messages) => {
        //         setActiveMessages(messages);
        //         console.log(messages);
        //
        //     });
        // setMessageCollection(collection);
        setSelectedUser(user);
    }

    function sendMessage() {
        const params = {
            message: messageInput,
            pushNotificationDeliveryOption: PushNotificationDeliveryOption.DEFAULT,        // Either DEFAULT or SUPPRESS
        };
        selectedUser.channel.sendUserMessage(params)
            .onSucceeded((message) => {
                setActiveMessages(prevState => [message, ...prevState]);
                setMessageInput("");
                // ...
            });
    }

    function handleInput(event) {
        if (event.nativeEvent.key === "Enter") {
            sendMessage();
        }
    }

    function loadUsers(businessId) {
        const groupChannelFilter = new GroupChannelFilter();
        groupChannelFilter.includeEmpty = true; // Optional.
        // groupChannelFilter.publicChannelFilter  = PublicChannelFilter.PUBLIC; // Retrieve public group channels. Optional.
        // groupChannelFilter.superChannelFilter = SuperChannelFilter.SUPER; // Retrieve Supergroup channels. Optional.

        const groupChannelCollection = sb.groupChannel.createGroupChannelCollection({
            filter: groupChannelFilter,
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
            // ...
        });
        if (groupChannelCollection.hasMore) {
            groupChannelCollection.loadMore()
                .then(channelResp => {
                    let channelsArr = [];
                    channelResp.forEach((channel) => {
                        let newChannel = {};
                        if (channel.members && channel.members.length > 1) {
                            channel.members.forEach((member) => {
                                if (member.userId !== businessId) {
                                    newChannel.id = member.userId;
                                    newChannel.nickname = member.nickname;
                                }
                            })
                            newChannel.channel = channel;
                            channelsArr.push(newChannel);
                        }
                    });
                    setChannels(channelsArr);
                })
        }
    }

    useEffect(()=>{
        const token = localStorage.getItem("token");
        getBusinessSummary(token)
            .then(resp => {
                if (resp.status === 200 && resp.data && resp.data.businessId) {
                    setBusinessSummary(resp.data);
                    try {
                        sb.connect(resp.data.businessId)
                            .then(connResp => {
                                setMessagingConnection(connResp);
                                loadUsers(resp.data.businessId);
                            })

                    } catch (err) {
                        // Handle error.
                    }
                }
            })
    }, []);

    return(
        <View style={styles.messageContainer}>
            <Header title="Messages" />
            <View style={styles.contentContainer}>
                <View style={styles.chatUsersContainer}>
                    <View style={styles.messagesHeader}>
                        <Text>Messages</Text>
                        <TouchableOpacity style={styles.plusIcon} onPress={openChatModal}>
                            <Image
                                source={plusIcon}
                                style={styles.addCampaignImg}
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.channelsView}>
                        {channels.map((creator, index) => (
                            <TouchableOpacity onPress={() => openChat(creator)}>
                                <View style={[index % 2 == 0 ? styles.creatorEvenRow : styles.creatorOddRow, selectedUser.id === creator.id ? styles.selectedMessageRow : ""]}>
                                    <Image
                                        source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id}}
                                        style={styles.creatorPreviewImage}
                                    />
                                    <View>
                                        <Text style={styles.boldBlueText}>{creator.nickname}</Text>
                                        {creator.channel.lastMessage && (
                                            <Text style={styles.lastMessageText}>{creator.channel.lastMessage.message}</Text>
                                        )}
                                    </View>
                                </View>
                            </TouchableOpacity>
                        ))}
                    </View>
                </View>
                <View style={styles.chatContainer}>
                    {/*<View style={styles.messageView}>*/}
                    {/*    {activeMessages.map(message => (*/}
                    {/*        <View>*/}
                    {/*            {message.sender.userId === businessSummary.businessId && (*/}
                    {/*                <View style={styles.sentMessageView}>*/}
                    {/*                    <Text style={styles.sentMessage}>{message.message}</Text>*/}
                    {/*                </View>*/}
                    {/*            )}*/}
                    {/*            {message.sender.userId !== businessSummary.businessId && (*/}
                    {/*                <View style={styles.receivedRow}>*/}
                    {/*                    <View>*/}
                    {/*                        <Image*/}
                    {/*                            source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + message.sender.userId}}*/}
                    {/*                            style={styles.messagePreviewImage}*/}
                    {/*                        />*/}
                    {/*                        <Text></Text>*/}
                    {/*                    </View>*/}
                    {/*                    <View style={styles.receivedMessageView}>*/}
                    {/*                        <Text style={styles.receivedMessage}>{message.message}</Text>*/}
                    {/*                    </View>*/}
                    {/*                </View>*/}
                    {/*            )}*/}
                    {/*        </View>*/}
                    {/*    ))}*/}
                    {/*</View>*/}
                    {/*<TextInput*/}
                    {/*    multiline*/}
                    {/*    rows={3}*/}
                    {/*    maxLength={200}*/}
                    {/*    // onChangeText={text => handleBioChanged(text)}*/}
                    {/*    value={messageInput}*/}
                    {/*    style={styles.notesInput}*/}
                    {/*    returnKeyType="done"*/}
                    {/*    onKeyPress={(event) => {*/}
                    {/*        handleInput(event);*/}
                    {/*    }}*/}
                    {/*    onChange={(event) => {*/}
                    {/*        setMessageInput(event.nativeEvent.text)*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<TouchableOpacity style={styles.sendTouchable} onPress={sendMessage}>*/}
                    {/*    <Image*/}
                    {/*        source={sendIcon}*/}
                    {/*        style={styles.sendIcon}*/}
                    {/*    />*/}
                    {/*</TouchableOpacity>*/}
                    {selectedUser && selectedUser.channel && selectedUser.channel.url && (
                        <SendbirdProvider
                            appId={'960C8598-DEDE-43E0-89FA-45442999623C'}
                            userId={businessSummary.businessId}
                        >
                            {/* GroupChannel should be always wrapped inside SendbirdProvider */}
                            <GroupChannel channelUrl={selectedUser.channel.url} />
                        </SendbirdProvider>
                    )}
                </View>
            </View>
            <Modal
                isOpen={newMessageModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"invite-modal"}
                style={styles.modal}
            >
                <View style={{alignItems: 'center', backgroundColor: 'white', borderRadius: 10, padding: 20}}>
                    {activeCreators.map(creator => (
                        <TouchableOpacity onPress={() => {createNewMessage(creator)}}>
                            <View style={styles.row}>
                                <Image
                                    source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id}}
                                    style={styles.creatorPreviewImage}
                                />
                                <Text>{creator.first_name} {creator.last_name}</Text>
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginTop: 20,
        alignItems: 'center',
    },
    creatorOddRow: {
        backgroundColor: "#f8f8f8",
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    selectedMessageRow: {
        backgroundColor: '#dae3f8',
    },
    creatorEvenRow: {
        backgroundColor: "white",
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
    },
    messageContainer: {
        height: '100vh',
    },
    messagesHeader: {
        flexDirection: 'row',
        marginTop: 20,
        justifyContent: 'center',
        marginBottom: 15,
    },
    plusIcon: {
        position: 'absolute',
        right: 10,
    },
    contentContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    addCampaignImg: {
        width: 20,
        height: 20,
        alignSelf: 'flex-end',
    },
    sendIcon: {
        width: 20,
        height: 20,
    },
    sendTouchable: {
        position: 'absolute',
        right: 30,
        bottom: 10,
    },
    modal: {
        overlay: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'rgba(57, 109, 224, .5)'
        },
        content: {
            margin: '20%',
            inset: 0,
            background: 'none',
            flex: 1,
            border: 'none',
            alignSelf: 'center',
            alignContent: 'center'
        }
    },
    creatorPreviewImage: {
        width: 80,
        height: 80,
        margin: 10,
        borderRadius: 50,
    },
    messagePreviewImage: {
        width: 60,
        height: 60,
        marginLeft: 10,
        borderRadius: 50,
    },
    chatContainer: {
        width: '70%',
        flex: 1,
        backgroundColor: 'white',
    },
    chatUsersContainer: {
        width: '25%',
    },
    notesInput: {
        padding: 10,
        display: 'flex',
        color: 'grey',
        fontFamily: "'lato', sans-serif",
        borderWidth: 1,
        borderColor: '#b8cbf5',
        borderRadius: 5,
        marginRight: 20,
    },
    messageView: {
        flex: 1,
        flexDirection: 'column-reverse',
        paddingBottom: 20,
    },
    sentMessage: {
        color: 'white',
    },
    sentMessageView: {
        backgroundColor: '#396de0',
        padding: 5,
        alignSelf: 'flex-end',
        marginTop: 10,
        borderRadius: 5,
        marginRight: 15,
        maxWidth: '40%',
    },
    receivedMessage: {
        color: '#1a1a1a',
    },
    receivedMessageView: {
        backgroundColor: '#f1f1f1',
        padding: 5,
        alignSelf: 'flex-end',
        marginTop: 10,
        borderRadius: 5,
        marginLeft: 15,
        maxWidth: '40%',
        flex: 1,
    },
    receivedRow: {
        flexDirection: 'row',
        alignItems: 'end',
        flex: 1,
    },
    boldBlueText: {
        color: '#396de0',
        fontWeight: 'bold',
    },
    lastMessageText: {
        color: '#737373',
        marginTop: 5,
    },
    channelsView: {
        backgroundColor: '#f8f8f8',
        flex: 1,
    }
});

export default Message
