import React, {useEffect, useState} from 'react'
import {signOut, fetchAuthSession, signInWithRedirect} from 'aws-amplify/auth';
import {Link, useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {getProfile} from "../services/UserService";
import '../stylesheets/Login.css';
import googleIcon from "../icons/google-icon.png";
import appleIcon from "../icons/apple-icon.png";
import mailIcon from "../icons/mail-icon.png";  
import { View, Text, Image, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native-web';
import EmailLogin from './EmailLogin';

const Login = () => {
    const [showEmailLogin, setShowEmailLogin] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [error, setErrorText] = useState("");
    const location = useLocation();
    const { width } = useWindowDimensions();
    const { errorMessage } = location.state || "";

    const navigate = useNavigate();

    const handleGoogleLogin = () => {
        console.log("google login")
        signOut().then(() => {
            signInWithRedirect({
                provider: "Google",
                options: {
                    preferPrivateSession: true,
                },
            }).then(() => {
                fetchAuthSession().then(session => {
                    getProfile(session.tokens.idToken.toString())
                        .then((user) => {
                            localStorage.setItem('business_profile', JSON.stringify(user.data))
                            localStorage.setItem('token', session.tokens.idToken.toString())
                            console.log(user)
                            if (searchParams.get("inviteId") != null) {
                                navigate('/join/' + searchParams.get("inviteId"));
                            } else {
                                navigate('/');
                            }
                        })
                }).catch(err => {
                    console.log(err)
                    setErrorText(err);
                })
            }).catch(err => {
                console.log(err)
                setErrorText(err);
            })
        })
    }

    const handleAppleLogin = () => {
        signOut().then(() => {
            signInWithRedirect({
                provider: "Apple",
                options: {
                    preferPrivateSession: true,
                },
            }).then(() => {
                fetchAuthSession().then(session => {
                    getProfile(session.tokens.idToken.toString())
                        .then((user) => {
                            localStorage.setItem('business_profile', JSON.stringify(user.data))
                            localStorage.setItem('token', session.tokens.idToken.toString())
                            if (searchParams.get("inviteId") != null) {
                                navigate('/join/' + searchParams.get("inviteId"));
                            } else {
                                navigate('/');
                            }
                        })
                }).catch(err => {
                    console.log(err)
                })
            }).catch(err => {
                console.log(err)
            })
        })
    }


    return (
        <View style={styles.container}>
            {width > 1000 && <View style={{flex: 1, width: "60%", height: "100%"}}>
                <Image
                    source={{uri: "https://dupe-photos.s3.amazonaws.com/Login_Return.jpg"}}
                    style={{width: "100%", height: "100%"}}
                />
            </View>}
            <View style={{alignItems: "center", width: "40%"}}>
                <Image
                    style={styles.loginLogo}
                    source={require("../icons/Dupe_Logo_Blue.png")}
                />
                {!showEmailLogin && <View>
                    <TouchableOpacity
                        onPress={handleGoogleLogin}
                        style={styles.socialBtn}>
                        <Image source={googleIcon} style={styles.googleIcon}></Image>
                        <Text style={[styles.socialLoginText, styles.lato]}>Continue with Google</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={handleAppleLogin}
                        style={styles.socialBtn}>
                        <Image source={appleIcon} style={styles.appleIcon}></Image>
                        <Text style={[styles.socialLoginText, styles.lato]}>Continue with Apple</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => setShowEmailLogin(true)}
                        style={styles.continueWithEmailBtn}>
                        <Image source={mailIcon} style={styles.mailIcon}></Image>
                        <Text style={[styles.loginText, styles.latoBold]}>Continue with Email</Text>
                    </TouchableOpacity>
                </View>}
                {showEmailLogin && <EmailLogin inviteId={searchParams.get("inviteId")} />}
                {errorMessage && <Text style={styles.errorText}>{errorMessage}</Text>}
                </View>
            </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f8f7f4',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row"
    },
    continueWithEmailBtn:{
        flexDirection: "row",
        width: 350,
        backgroundColor:"#236fea",
        borderRadius:25,
        height:50,
        alignItems:"center",
        justifyContent:"center",
        marginTop: 10,
        marginBottom: 10
    },  
    socialBtn:{
        flexDirection: "row",
        width: 350,
        backgroundColor: "white",
        borderRadius:25,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "black",
        height:50,
        alignItems:"center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10
    },
    loginLogo:{
        width: 100,
        height: 50
    },
    loginText: {
        color: "white",
        fontSize: 20,
    },
    socialLoginText: {
        color: "black",
        fontSize: 20,
    },
    mailIcon: {
        width: 20,
        height: 20,
        marginRight: 10
    },
    appleIcon: {
        width: 20,
        height: 20,
        marginBottom: 3,
        marginRight: 10
    },
    googleIcon: {
        width: 30,
        height: 30,
        marginRight: 10
    },
    lato: {
        fontFamily: "'Lato', sans-serif"
    },
    latoBold: {
        fontFamily: "'Lato', sans-serif",
        fontWeight: 700
    },
    errorText: {
        color: 'red',
        fontFamily: "'Lato', sans-serif",
        fontSize: 16
    }
}); 

export default Login