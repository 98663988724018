import React, {useEffect, useRef, useState} from 'react'
import "../stylesheets/BrandHub.css";
import {getCampaigns, getContentHub} from "../services/CampaignService";
import Content from "./Content";
import {getBusinessContent, getBusinessCreators} from "../services/BusinessService";
import {Image, StyleSheet, View} from 'react-native-web';



const ContentHub = () => {

    const [content, setContent] = useState([])
    const [additionalContent, setAdditionalContent] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [creators, setCreators] = useState([])
    const selectedCampaignId = useRef();
    const [page, setPage] = useState(2)

    useEffect(()=>{
        const token = localStorage.getItem("token");
        getBusinessContent(null, null, null, token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setContent(resp.data);
                }
            })
        getCampaigns(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCampaigns(resp.data);
                }
            })
        getBusinessCreators(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setCreators(resp.data);
                }
            })
    }, []);

    function getCampaignContent(campaignId) {
        const token = localStorage.getItem("token");
        getBusinessContent(null, campaignId, null, token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setContent(resp.data);
                    selectedCampaignId.current = resp.data.id;
                    setPage(2);
                }
            })
    }

    function getUsersCampaignContent(userId) {
        const token = localStorage.getItem("token");
        getBusinessContent(null, null, userId, token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setContent(resp.data);
                    selectedCampaignId.current = resp.data.id;
                    setPage(2);
                }
            })
    }

    function retrieveNextContent() {
        const token = localStorage.getItem("token");
        console.log(selectedCampaignId.current);
        getBusinessContent(page, selectedCampaignId.current, null, token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setAdditionalContent(resp.data);
                    setPage(page+1);
                }
            })
    }

    return(
        <div>
            <div style={styles.contentDiv}>
                <Content retrieveNextContent={retrieveNextContent} content={content} additionalContent={additionalContent}/>
            </div>
            <div style={styles.settingsDiv}>
                <div style={styles.settingsHeaderDiv}>
                    <h3>Filters</h3>
                </div>
                <h5>Campaigns</h5>
                {campaigns && campaigns.length > 0 && campaigns.map(campaign => (
                    <div style={styles.campaignPreviewDiv} onClick={() => {getCampaignContent(campaign.id)}}>
                        <img style={styles.campaignImg} src={"https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + campaign.imageId} />
                        <p style={styles.inline}>{campaign.name}</p>
                    </div>
                ))}
                <h5>Creators</h5>
                {creators && creators.length > 0 && creators.map(creator => (
                    <div style={styles.campaignPreviewDiv} onClick={() => {getUsersCampaignContent(creator.id)}}>
                        <Image style={styles.campaignImg} source={{uri: "https://d3p3fw3rutb1if.cloudfront.net/users/" + creator.id}} />
                        <div style={styles.creatorInfoDiv}>
                            <p style={styles.creatorInfoP}>{creator.first_name} {creator.last_name}</p>
                            <p style={styles.creatorInfoP}>{creator.posts} uploads</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

const styles = StyleSheet.create({
    settingsDiv: {
        display: 'inline-block',
        width: '20%',
        height: '100vh',
        verticalAlign: 'top',
    },
    contentDiv: {
        display: 'inline-block',
        width: '80%',
        height: '100vh',
        overflow: 'scroll',
        verticalAlign: 'top',
    },
    settingsHeaderDiv: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    campaignImg: {
        width: '50px',
        height: '50px',
        display: 'inline-block',
        objectFit: 'cover',
        borderRadius: '5px',
    },
    campaignPreviewDiv: {
        cursor: 'pointer',
        paddingLeft: '10px',
        justifyContent: 'center',
    },
    inline: {
        display: 'inline-block'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    creatorInfoP: {
        marginBottom: 0,
    },
    creatorInfoDiv: {
        display: 'inline-block',
        verticalAlign: 'top',
        marginLeft: '10px',
    }
});

export default ContentHub
