import axios from "axios";

export async function getContent() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content"
    return await axios.get(url);
}

export async function getContent2() {
    let url = "https://content-api-prod-6gxsdymdsq-ue.a.run.app/api/v1/content?page=2"
    return await axios.get(url);
}

export async function getContentSummary(contentId, userId) {
    const url = process.env.REACT_APP_DUPE_BACKEND_ENDPOINT + "/api/v1/content/" + contentId + "/preview";
    return await axios.get(url, {headers: {'USER-ID': userId}});
}

export async function toggleLike(contentId, token) {
    return await axios.post(process.env.REACT_APP_DUPE_BACKEND_ENDPOINT + '/api/v1/content/' + contentId + "/like", {}, {headers: {'X-CLIENT-ID': token}});
}

export async function getDownloadFile(contentId, token) {
    const url = process.env.REACT_APP_DUPE_BACKEND_ENDPOINT + "/api/v1/content/" + contentId + "/download";
    const config = { responseType: 'blob', headers: {'X-CLIENT-ID': token}};
    return await axios.get(url, config);
}

export async function getS3Content(contentId) {
    let url = "https://d3p3fw3rutb1if.cloudfront.net/photos/"+contentId;
    return await axios.get(url, {responseType: "blob"});
}