import axios from "axios";

export async function createCampaign(campaign, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns";
    const result = await axios.post(url, campaign, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getCampaigns(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getCampaignByName(token, campaign) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/" + campaign;
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createCampaignCreator(request, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/creators";
    const result = await axios.post(url, request, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getCampaignCreators(token, campaignId) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/" + campaignId + "/creators";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createCampaignImage(request, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/image";
    const result = await axios.post(url, request, { headers: {'Content-Type': 'multipart/form-data', 'X-CLIENT-ID': token}});
    return result;
}

export async function updateCampaignCreator(campaignId, request, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/" + campaignId + "/creators";
    const result = await axios.put(url, request, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createCampaignAttachment(request, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/attachment";
    const result = await axios.post(url, request, { headers: {'Content-Type': 'multipart/form-data', 'X-CLIENT-ID': token}});
    return result;
}

export async function getContentHub(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/campaigns/content/summary";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}