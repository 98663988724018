import axios from "axios";

export async function getSocialAccounts(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/social/accounts";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBrandHub(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/brand_hub";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function updateBusinessInfo(req, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses";
    const result = await axios.put(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBusinessUsers(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/users";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function inviteUser(req, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/users/invite";
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getSubscription(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/subscription";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getStripeIntent(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/payment_methods/intent";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createPaymentMethod(req, token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/payment_methods";
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getPaymentMethods(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/payment_methods";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBusinessContent(page, campaignId, userId, token) {
    let url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/content/all?page=";
    if (page != null) {
        url = url + page;
    } else {
        url = url + '1';
    }
    if (campaignId != null) {
        url = url + '&campaignId=' + campaignId;
    }
    if (userId != null) {
        url = url + '&userId=' + userId;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBusinessCreators(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/creators";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBusinessNotifications(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/notifications";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getActiveBusinessCreators(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/creators/active";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBusinessSummary(token) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/summary";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getBusinessInviteSummary(token, inviteId) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/invite/" + inviteId + "/summary";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function acceptBusinessInviteSummary(token, inviteId) {
    const url = process.env.REACT_APP_BACKEND_ENDPOINT + "/api/v1/businesses/invite/" + inviteId + "/accept";
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}