import './App.css';
import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import {View} from "react-native-web";
import Login from "./components/Login";
import React, {useEffect} from "react";
import SideBar from "./components/SideBar";
import CreatorSearch from "./components/CreatorSearch";
import Account from "./components/Account";
import AccessToken from "./components/AccessToken";
import {Amplify} from "aws-amplify";
import awsconfig from './aws-exports';
import Grid from "./components/Grid";
import BrandHub from "./components/BrandHub";
import Outreach from "./components/Outreach";
import CreateProject from "./components/CreateProject";
import Campaign from "./components/Campaign";
import ContentHub from "./components/ContentHub";
import EditCampaign from "./components/EditCampaign";
import {Hub} from "aws-amplify/utils";
import {fetchAuthSession} from 'aws-amplify/auth';
import {getProfile} from './services/UserService';
import Message from "./components/Message";
import Join from "./components/Join";


export default function App() {
    const navigate = useNavigate();

    awsconfig.oauth = {
        domain: "auth.dupephotos.com",
        scope: [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        redirectSignIn: "http://localhost:3000/oauth/",
        redirectSignOut: "exp+dupe-mobile://logout",
        responseType: "code"
    };
    Amplify.configure(awsconfig);

    // useEffect(()=>{
    //     const profile = localStorage.getItem("business_profile");
    //     if (!profile) {
    //         localStorage.removeItem("token");
    //         navigate('/login')
    //     }
    // }, []);

    const successfulLogin = () => {
        fetchAuthSession().then(session => {
            console.log("session", session)
            getProfile(session.tokens.idToken.toString())
                .then((user) => {
                    localStorage.setItem('business_profile', JSON.stringify(user.data))
                    localStorage.setItem('token', session.tokens.idToken.toString())
                    console.log(user)
                    navigate('/');
                }).catch(err => {
                    console.log(err)
                    const errorMessage = "You must be added to a Dupe Biz organization to login."
                    navigate('/login', {state: {errorMessage}})
                })
            }).catch(err => {
                console.log(err)
                const errorMessage = "Error logging in."
                navigate('/login', {state: {errorMessage}})
            })
    }

    const loggedOut = () => {
        localStorage.removeItem("token");
        navigate('/login')
    }

    // Hub.listen('auth', (data) => {
    //     switch (data.payload.event) {
    //         case "signedOut":
    //         case "tokenRefresh_failure":
    //             loggedOut()
    //             return
    //         // case "signedIn":
    //         // case "signInWithRedirect":
    //         //     console.log("sign in with redirect")
    //         //     successfulLogin()
    //         //     return
    //     }
    // });

  return (
      <View style={{height: '100vh', backgroundColor: '#f1f1f1'}}>
        <Routes>
            <Route path="/" element={<SideBar/>}>
                <Route path="home" element={<BrandHub/>} />
                <Route path="creators/search" element={<CreatorSearch/>} />
                <Route path="account" element={<Account/>} />
                <Route path="account/callback" element={<AccessToken/>} />
                <Route path="grid" element={<Grid/>} />
                <Route path="outreach" element={<Outreach/>} />
                <Route path="projects/create" element={<CreateProject/>} />
                <Route path="campaigns/:campaignSlug/edit" element={<EditCampaign/>} />
                <Route path="campaigns/create" element={<EditCampaign/>} />
                <Route path="campaigns/:campaignSlug" element={<Campaign/>} />
                <Route path="content" element={<ContentHub/>} />
                <Route path="messages" element={<Message/>} />
                <Route path="" element={<Navigate to="/home" />} />
            </Route>
            <Route path="/login" element={<Login />}/>
            <Route path="/join/:inviteId" element={<Join />}/>
        </Routes>
    </View>
  );
}
