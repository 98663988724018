import React, {useEffect, useState} from 'react';
import openEye from "../icons/DupeIcons_Eye-Open.png";
import closedEye from "../icons/eye-closed.png";
import { signOut, signIn, fetchAuthSession } from 'aws-amplify/auth';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, Image } from 'react-native-web';
import { getProfile } from "../services/UserService";
import {useNavigate, useSearchParams} from 'react-router-dom';

const EmailLogin = ({inviteId}) => {
    const [email, setEmail] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const navigate = useNavigate();

    const handleLogin = () => {
        if (email && password) {
            signOut().then(() => {
                signIn({
                    password: password,
                    username: email,
                    options: {
                        authFlowType: 'USER_PASSWORD_AUTH',
                    },
                })
                    .then(() => {
                        fetchAuthSession().then(session => {
                            getProfile(session.tokens.idToken.toString())
                                .then((user) => {
                                    localStorage.setItem('business_profile', JSON.stringify(user.data))
                                    localStorage.setItem('token', session.tokens.idToken.toString())
                                    if (inviteId != null) {
                                        navigate('/join/' + inviteId);
                                    } else {
                                        navigate('/');
                                    }
                                })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        setErrorText(err.message)
                    });
            })
        }
    };

    useEffect(() => {
        console.log(inviteId);

    }, []);

    return (
        <View style={{alignItems: 'center'}}>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.inputText}
                    placeholder="Email"
                    placeholderTextColor="white"
                    selectionColor="white"
                    autoCapitalize="none"
                    value={email}
                    onChangeText={text => setEmail(text)}
                    onSubmitEditing={handleLogin}
                />
            </View>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.inputText}
                    placeholder="Password"
                    placeholderTextColor="white"
                    selectionColor="white"
                    autoCapitalize="none"
                    secureTextEntry={!showPassword}
                    value={password}
                    onChangeText={text => setPassword(text)}
                    //onSubmitEditing={handleLogin}
                />
                <TouchableOpacity onPress={() => setShowPassword(!showPassword)}>
                    <Image source={showPassword ? openEye : closedEye} style={{ width: 20, height: 20, marginLeft: 10 }} />
                </TouchableOpacity>
            </View>
            <TouchableOpacity
                onPress={handleLogin}
                style={styles.loginBtn}>
                <Text style={[styles.loginText]}>Login</Text>
            </TouchableOpacity>
            {errorText && <Text style={styles.errorText}>{errorText}</Text>}
        </View>
    )
}

const styles = StyleSheet.create({
    inputView: {
        width: 350,
        backgroundColor:"#236fea",
        borderRadius:25,
        height:50,
        marginBottom:20,
        justifyContent:"space-between",
        alignItems: "center",   
        padding: 20,
        flexDirection: "row",
    },
    inputText: {
        width: "100%",
        height: 70,
        color: "white",
        fontFamily: "'Lato', sans-serif",
        outlineWidth: 0
    },
    loginBtn:{
        width: 350,
        backgroundColor:"#c6ca53",
        borderRadius:25,
        height:50,
        alignItems:"center",
        justifyContent:"center",
        marginTop:40,
        marginBottom:10
    },
    loginText: {
        color: "white",
        fontFamily: "'Lato', sans-serif",
        fontSize: 20
    },
    errorText: {
        color: 'red',
        fontFamily: "'Lato', sans-serif",
        fontSize: 16
    }
});


export default EmailLogin;