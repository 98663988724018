import React, {useEffect, useState} from 'react'
import {
    getBrandHub,
    getBusinessNotifications,
    getSocialAccounts,
    updateBusinessInfo
} from "../services/BusinessService";
import {useNavigate} from "react-router-dom";
import plusIcon from "../icons/grey-plus-btn.png";
import pencilIcon from "../icons/blue-pencil.png";
import instagramIcon from "../icons/DupeIcons-Instagram.png";
import tiktokIcon from "../icons/DupeIcons-TikTOk.png";
import "../stylesheets/BrandHub.css";
import Modal from "react-modal";
import {createCampaignImage} from "../services/CampaignService";
import CampaignPreview from './CampaignPreview';
import Header from './Header';

const BrandHub = () => {
    const [businessData, setBusinessData] = useState({})
    const [newBizInfo, setNewBizInfo] = useState({})
    const [notifications, setNotifications] = useState({})
    const [editBizModal, setEditBizModal] = useState(false)
    const navigate = useNavigate();

    const defaultStyle = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    function afterOpenModal() {

    }
    function closeModal() {
        setEditBizModal(false);
    }
    function openModal() {
        setNewBizInfo(businessData.business)
        setEditBizModal(true);
    }

    const updateName = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            name: event.target.value       // update the value of specific key
        }))
    }

    const updateDescription = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            description: event.target.value       // update the value of specific key
        }))
    }

    const updateInstagram = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            instagramHandle: event.target.value       // update the value of specific key
        }))
    }
    const updateTikTok = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            tikTokHandle: event.target.value       // update the value of specific key
        }))
    }
    const updateYoutube = (event) => {
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            youtubeHandle: event.target.value       // update the value of specific key
        }))
    }

    const setUploadImage = (event) => {
        event.preventDefault();
        setNewBizInfo(prevState => ({
            ...prevState,    // keep all other key-value pairs
            logo: event.target.files[0],     // update the value of specific key
            newLogo: true
        }))
    }

    function saveBusinessInfo(event) {
        event.preventDefault();
        const token = localStorage.getItem("token");
        let req = {};
        req.name = newBizInfo.name;
        req.description = newBizInfo.description;
        req.logoImgId = newBizInfo.logoImgId;
        req.instagramHandle = newBizInfo.instagramHandle;
        req.tikTokHandle = newBizInfo.tikTokHandle;
        req.youtubeHandle = newBizInfo.youtubeHandle;
        if (newBizInfo.newLogo) {
            let formData = new FormData();
            formData.append("img", newBizInfo.logo);
            console.log(newBizInfo);
            createCampaignImage(formData, token)
                .then(resp => {
                    if (resp.status === 200 && resp.data && resp.data.img_id) {
                        req.newLogo = true;
                        req.logoImgId = resp.data.img_id;
                    }
                    updateBusinessInfo(req, token)
                        .then(bizResp => {
                            if (bizResp.status === 200 && bizResp.data) {
                                setBusinessData(prevState => ({
                                    ...prevState,
                                    business: bizResp.data
                                }))
                            }
                            closeModal();
                        })
                        .catch(err => {
                            closeModal();
                        })
                });
        } else {
            updateBusinessInfo(req, token)
                .then(bizResp => {
                    if (bizResp.status === 200 && bizResp.data) {
                        setBusinessData(prevState => ({
                            ...prevState,
                            business: bizResp.data
                        }))
                    }
                    closeModal();
                })
                .catch(err => {
                    closeModal();
                })
        }
    }

    useEffect(()=>{
        const token = localStorage.getItem("token");
        getBrandHub(token)
            .then(resp => {
                if (resp.status == 200 && resp.data) {
                    setBusinessData(resp.data);
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem('business_profile')
                    navigate('/login')
                }
            })
        getBusinessNotifications(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setNotifications(resp.data);
                }
            })

    }, []);

    return(
        <div>
            <Header title={"Brand Hub"}/>    
            <div className={"hub-top-container"}>
                <div className={"business-overview-div"}>
                    <div className={"white-container-div business-info-div"}>
                        <div className={"brand-logo-div"}>
                            {businessData.business && businessData.business.logoExists && (
                                <img className={"logo-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + businessData.business.logoImgId}/>
                            )}
                            <div className={"brand-socials-div"}>
                                {businessData.business && businessData.business.instagramHandle && (
                                    <a href={"https://www.instagram.com/" + businessData.business.instagramHandle} target={"_blank"}>
                                        <img src={instagramIcon} className={"brand-social-icon"} />
                                    </a>
                                )}
                                {businessData.business && businessData.business.instagramHandle && (
                                    <a href={"https://www.tiktok.com/@" + businessData.business.tikTokHandle} target={"_blank"}>
                                        <img src={tiktokIcon} className={"brand-social-icon"} />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className={"brand-info-div"}>
                            {businessData.business && (
                                <h4 className={"brand-h font-bold"}>{businessData.business.name}</h4>
                            )}
                            {businessData.business && (
                                <p className={""}>{businessData.business.description}</p>
                            )}
                        </div>
                        <img className={"brand-pencil-img absolute-10-10"} src={pencilIcon} onClick={openModal} />
                    </div>
                    <div className={"white-container-div notifications-div"}>
                        <h3 className={"font-bold blue-text notification-h"}>Notifications</h3>
                        {(!notifications || notifications.length < 1) && (
                            <p>You have no notifications yet</p>
                        )}
                        {notifications && notifications.length > 0 && notifications.map(notification => (
                            <div className={"notification-div"}>
                                <span className={"bullet-span"} />
                                {notification.type === "CAMPAIGN_CONTENT_UPLOADED" && (
                                    <div className={"notification-info-div"}>
                                        <div className={"campaign-notification-text-div"}>
                                            <p className={"light-grey bold-p p-14"}>{notification.actor_first_name} has uploaded content</p>
                                            <p className={"light-grey p-14"}>- {notification.user_name}</p>
                                        </div>
                                        <p className={"elapsed-time-p light-grey margin-0"}>{notification.elapsed_time}</p>
                                    </div>
                                )}
                                {notification.type === "CAMPAIGN_APPLIED" && (
                                    <div className={"notification-info-div"}>
                                        <div className={"campaign-notification-text-div"}>
                                            <p className={"light-grey bold-p p-14"}>{notification.actor_first_name} has applied to your campaign</p>
                                            <p className={"light-grey p-14"}>- {notification.user_name}</p>
                                        </div>
                                        <p className={"elapsed-time-p light-grey margin-0"}>{notification.elapsed_time}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={"white-container-div campaign-hub-div"}>
                    <h3 className={"font-bold blue-text"}>Active Campaigns</h3>
                    <div className={"campaigns-div"}>
                        {businessData.campaigns && businessData.campaigns.map(campaign => (
                            <CampaignPreview key={campaign.id} campaign={campaign} alternateBackground={true}/>
                        ))}
                    </div>
                </div>
            </div>
            {businessData.content && businessData.content.length > 0 && (
                <div>
                    <h3 className={"font-bold blue-text"}>Your Content</h3>
                    <div className={"white-container-div content-preview-div"}>
                        {businessData.content && businessData.content.map( image => (
                            <img className={"content-preview-img"} src={"data:" + image.image_type + ";base64," + image.img} />
                        ))}
                    </div>
                </div>
            )}
            <div>
                <div className={"creators-spotlight-div"}>
                    <h3 className={"font-bold blue-text"}>Creators We're Loving</h3>
                    {businessData.creators && businessData.creators.map( creator => (
                        <div className={"hub-creator-div"}>
                            <div className={"creator-header-div"}>
                                <img className={"creator-hub-preview-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/users-preview/" + creator.id} />
                                <div className={"creator-info-div"}>
                                    <p className={"username-p"}>@{creator.username}</p>
                                    <p className={"follower-p"}>{creator.follower_count} followers</p>
                                </div>
                            </div>
                            <div>
                                <p className={"creator-section-p light-grey"}>Aesthetics</p>
                                <div className={"creator-aesthetics-div"}>
                                    {creator.aesthetics && creator.aesthetics.map(aesthetic => (
                                        <p>{aesthetic}</p>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <p className={"creator-section-p light-grey"}>Content</p>
                                <div className={"creator-content-div"}>
                                    {creator.content && creator.content.map(post => (
                                        <img className={"creator-content-preview-img"} src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + post.img_preview_id} />
                                        ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal
                isOpen={editBizModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"business-edit-modal"}
                style={defaultStyle}
            >
                <div className={"edit-modal-div"}>
                    <form>
                        <div className={"edit-business-header-div"}>
                            <h3 className={"font-bold blue-color"}>Edit Business Info</h3>
                        </div>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <div className={"headshot-box"}>
                                    {newBizInfo.newLogo && (
                                        <img className={"logo-preview"}
                                             src={URL.createObjectURL(newBizInfo.logo)} onClick={()=> {document.getElementById('file').click();}}/>
                                    )}
                                    {!newBizInfo.newLogo && newBizInfo.logoExists && (
                                        <img className={"logo-preview"} src={"https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + newBizInfo.logoImgId} onClick={()=> {document.getElementById('file').click();}} />
                                    )}
                                    {!newBizInfo.newLogo && !newBizInfo.logoExists && (
                                        <img className={"logo-preview"}
                                             src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXw3NjA4Mjc3NHx8ZW58MHx8fHw%3D&w=1000&q=80" onClick={()=> {document.getElementById('file').click();}}/>
                                    )}
                                </div>
                                <input type="file" style={{display:"none"}} id="file" name="file"
                                       onChange={(event) => {
                                           setUploadImage(event);
                                       }}/>
                            </div>
                            <div className={"col-8"}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control grey-input" value={newBizInfo.name} maxLength="20" onChange={updateName} placeholder="First name"/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className={"relative-div"}>
                                        <textarea type="text" value={newBizInfo.description} onChange={updateDescription}
                                                  maxLength="115" rows={"4"} className="form-control grey-textarea"
                                                  id="descriptionInput" placeholder="Bio"/>
                                        <span className={"profile-textarea-limit-span"}>{115 - (newBizInfo.description ? newBizInfo.description.length : 0)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col relative-div">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input" value={newBizInfo.instagramHandle} onChange={updateInstagram} placeholder="Instagram"/>
                            </div>
                            <div className="col relative-div">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input" value={newBizInfo.tikTokHandle} onChange={updateTikTok} placeholder="TikTok"/>
                            </div>
                            <div className="col relative-div">
                                <span className="at-span font-medium" id="basic-addon1">@</span>
                                <input type="text" className="form-control grey-input at-input" value={newBizInfo.youtubeHandle} onChange={updateYoutube} placeholder="Youtube"/>
                            </div>
                        </div>
                        <div className={"edit-profile-footer"}>
                            <button className={"light-grey-btn"}>Cancel</button>
                            <button type="submit" className="btn blue-btn" onClick={saveBusinessInfo}>Submit</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>

    );
}

export default BrandHub
