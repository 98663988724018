import {StyleSheet, View, Text, Image, TouchableOpacity, useWindowDimensions} from "react-native-web";
import React, {useEffect, useState} from "react";
import dupeLogo from "../icons/Dupe_Logo_Blue.png";
import {acceptBusinessInviteSummary, getBusinessInviteSummary} from "../services/BusinessService";
import {useNavigate, useParams} from "react-router-dom";
import Button from "./Button";

const Join = () => {

    const [inviteSummary, setInviteSummary] = useState({})
    const { width } = useWindowDimensions();
    let { inviteId } = useParams();
    const navigate = useNavigate();

    function acceptInvite() {
        const token = localStorage.getItem("token");
        acceptBusinessInviteSummary(token, inviteId)
            .then(resp => {
                if (resp.status === 200) {
                    navigate('/home');
                }
            })
    }

    useEffect(()=> {
        const token = localStorage.getItem("token");
        if (token != null) {
            getBusinessInviteSummary(token, inviteId)
                .then(resp => {
                    if (resp.status === 200 && resp.data) {
                        setInviteSummary(resp.data);
                    }
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        navigate('/login?inviteId=' + inviteId)
                    }
                })
        } else {
            navigate('/login?inviteId=' + inviteId)
        }
    }, []);
    return(
        <View style={styles.container}>
            {width > 1000 && <View style={{flex: 1, width: "60%", height: "100%"}}>
                <Image
                    source={{uri: "https://dupe-photos.s3.amazonaws.com/Login_Return.jpg"}}
                    style={{width: "100%", height: "100%"}}
                />
            </View>}
            <View style={{alignItems: "center", width: "40%"}}>
                <View style={styles.logoContainer}>
                    <Image source={dupeLogo} style={styles.logo} />
                    <Text style={styles.logoText}>Biz</Text>
                </View>
                <View style={styles.row}>
                    <Image source={"https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + inviteSummary.logoImgId} style={styles.brandLogo} />
                    <Text>{inviteSummary.name}</Text>
                </View>
                <View style={styles.textRow}>
                    <Text>wants you to join their Biz!</Text>
                </View>
                <View style={styles.row}>
                    <Button variant={'secondary'} text={"Decline"} />
                    <Button onPress={acceptInvite} text={"Join"} />
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f8f7f4',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row"
    },
    continueWithEmailBtn:{
        flexDirection: "row",
        width: 350,
        backgroundColor:"#236fea",
        borderRadius:25,
        height:50,
        alignItems:"center",
        justifyContent:"center",
        marginTop: 10,
        marginBottom: 10
    },
    socialBtn:{
        flexDirection: "row",
        width: 350,
        backgroundColor: "white",
        borderRadius:25,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "black",
        height:50,
        alignItems:"center",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10
    },
    loginLogo:{
        width: 100,
        height: 50
    },
    loginText: {
        color: "white",
        fontSize: 20,
    },
    socialLoginText: {
        color: "black",
        fontSize: 20,
    },
    mailIcon: {
        width: 20,
        height: 20,
        marginRight: 10
    },
    appleIcon: {
        width: 20,
        height: 20,
        marginBottom: 3,
        marginRight: 10
    },
    googleIcon: {
        width: 30,
        height: 30,
        marginRight: 10
    },
    lato: {
        fontFamily: "'Lato', sans-serif"
    },
    latoBold: {
        fontFamily: "'Lato', sans-serif",
        fontWeight: 700
    },
    logoContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginBottom: 30,
    },
    logo: {
        width: 120,
        height: 50,
        resizeMode: 'contain',
    },
    logoText: {
        fontSize: 50,
        fontFamily: "'forma-djr-display', sans-serif",
        color: '#396de0',
    },
    brandLogo: {
        width: 50,
        height: 50,
        borderRadius: '50%',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textRow: {
        marginTop: 20,
        marginBottom: 40,
    }
})

export default Join